<template>
  <div>
    <div class="flex class h-screen">
      <div class="w-64 bg-gray-200 border-r">
        <div class="text-gray-900 border-b border-gray-200 p-2 px-4 bg-white">
          2020020202001.pdf
        </div>
        <div class="text-gray-900 border-b border-gray-200 p-2 px-4 bg-blue-50 font-medium">
          2020020202002.pdf
        </div>
        <div class="text-gray-900 border-b border-gray-200 p-2 px-4 bg-white">
          2020020202003.pdf
        </div>
        <div class="text-gray-900 border-b border-gray-200 p-2 px-4 bg-white">
          2020020202004.pdf
        </div>
        <div class="text-gray-900 border-b border-gray-200 p-2 px-4 bg-white">
          2020020202005.pdf
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <div class="text-center text-4xl font-semibold leading-10">
          Prévisualisation du document
        </div>
      </div>
      <div class="bg-white w-72 px-2 flex flex-col justify-end border-l">
        <div class="py-2">
          <ais-instant-search
            index-name="relations"
            :search-client="searchClient"
          >
            <ais-autocomplete
              :indices="[{ label: 'Relations', value: 'relations' }]"
            >
              <div slot-scope="{ currentRefinement, indices, refine }">
                <input
                  type="search"
                  :value="currentRefinement"
                  placeholder="Search for a product"
                  @input="refine($event.currentTarget.value)"
                />
                <ul
                  v-if="currentRefinement"
                  v-for="index in indices"
                  :key="index.label"
                >
                  <li>
                    <h3>{{ index.label }}</h3>
                    <ul>
                      <li v-for="hit in index.hits" :key="hit.objectID">
                        <ais-highlight attribute="company" :hit="hit" />
                        <ais-highlight attribute="givenName" :hit="hit" />
                        <ais-highlight attribute="familyName" :hit="hit" />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </ais-autocomplete>
          </ais-instant-search>
          <sqr-input-text label="Relation" />
          <sqr-input-text class="mt-4" label="Police" />
          <sqr-input-text class="mt-4" label="Nom du fichier" />
          <sqr-button label="Classer" class="mt-5 w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import SqrInputText from '@/sqrd-ui/SqrInputText';
import SqrButton from '@/sqrd-ui/SqrButton';
export default {
  name: 'Mailroom',
  components: {
    SqrInputText,
    SqrButton
  },
  data() {
    return {
      currentRefinement: '',
      searchClient: algoliasearch(
        '9QYDFO61P1',
        '65d5adaabb69a8cabccb802ff13eaae1'
      )
    };
  },
  metaInfo: {
    title: 'Courrier à classer'
  }
};
</script>
